<template>
    <b-modal size="lg" :title="$t('menu.subcategory.product_order_options')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input v-model="addObject.name" autofocus/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('keys.additional_options')">
                    <v-select v-model="checkOptions" appendToBody label="name" :options="filteredOptions" multiple @input="handleSelection" :selectable="item => !item.disabled">
                        <template #option="{name, disabled}">
                            <span class="mr-2">{{name}} {{disabled}}</span>
                            <b-badge variant="danger" v-if="disabled">
                                {{$t('config.display_device_type.no_data')}}
                            </b-badge>
                        </template>
                    </v-select>
                </b-form-group>
                <b-form-group :label="$t('keys.additional_order_options')">
                    <v-select v-model="addObject.additional_order_options" taggable appendToBody multiple :noDrop="true"/>
                </b-form-group>

                <div class="d-flex justify-content-end mb-1">
                    <b-form-checkbox class="mr-2 my-auto h-full pt-0.5 disabled-items" switch v-model="showDisabled" @change="recomputeProducts">{{ $t('keys.show_disabled_products')}}</b-form-checkbox>
                </div>
                <TableWithCheckBox ref="tableOrderOption" v-if="arraysLoad" :columnDefs="columnDefs" :rowData="products" :selected="addObject.product_ids" selectValue="id" :findValueInArray="true" @selectionChanged="selectionChanged"/>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BBadge, BButton, BFormCheckbox, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import vSelect from 'vue-select'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BBadge,
            BFormCheckbox,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            TableWithCheckBox
        },
        props: {
            options: {
                type: Array
            },
            productsRaw: {
                type: Array
            }
        },
        data() {
            return {
                arraysLoad: false,
                modalActive: false,
                addObject: {
                    name: '',
                    additional_options: {},
                    product_ids: []
                },
                required,
                checkOptions: [],
                editing: false,
                productSubcategories: [],
                showDisabled: false,
                products: [],
                originalSelection: []
            }
        },
        computed: {
            columnDefs() {
                return [
                    {headerName: this.$t('table_fields.products'), field: 'name', editable: false, filter: true},
                    {
                        headerName: this.$t('table_fields.subcategory'),
                        field: 'product_category_id',
                        editable: true,
                        filter: true,
                        floatingFilterComponentParams: () => {
                            return {type: 'select', values: this.productSubcategories}
                        },
                        cellEditorFramework: 'selectEditor',
                        cellEditorParams: () => {
                            return {values: this.productSubcategories}
                        },
                        cellRenderer: (params) => this.getCategoryName(params.value)
                    }
                ]
            },
            filteredOptions() {
                return this.options.map(option => {
                    return {
                        ...option,
                        disabled: this.shouldDisableOption(option.value)
                    }
                })
            }
        },
        methods: {
            async loadData() {
                try {
                    const res = await this.$http.get('/api/client/v1/product_subcategories/')
                    this.productSubcategories = res.data ?? []
                    this.arraysLoad = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                }
            },
            async recomputeProducts() {
                const prods = this.productsRaw.map((product) => {
                    return {
                        ...product,
                        enabled: !product.disabled
                    }
                })

                if (this.showDisabled) {
                    this.products = prods
                } else {
                    this.products = prods.filter((x) => !x.disabled)
                }

                if (this.$refs.tableOrderOption) {
                    const fm = this.$refs.tableOrderOption.getFilterModel()
                    await this.$refs.tableOrderOption.refresh()
                    await this.$refs.tableOrderOption.updateSelected(this.addObject.product_ids)
                    this.$refs.tableOrderOption.setFilterModel(fm)
                  
                }
            },
            getCategoryName(value) {
                if (value) {
                    const item = this.productSubcategories.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                    this.originalSelection = this.addObject.product_ids

                    if (this.addObject.additional_options) {
                        this.checkOptions = []
                        for (const option of this.options) {
                            if (this.addObject.additional_options[option.value] === 1) this.checkOptions.push(option)
                        }
                    }
                } else {
                    this.checkOptions = []
                    this.editing = false
                    this.addObject = {
                        name: '',
                        additional_options: {},
                        product_ids: []
                    }
                }
                this.showDisabled = false
                this.recomputeProducts()
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.addObject.additional_options = {}
                    if (this.checkOptions && this.checkOptions.length > 0) {
                        for (const option of this.options) {
                            const index = this.checkOptions.findIndex(x => x.value === option.value)
                            if (index > -1) this.addObject.additional_options[option.value] = 1
                        }
                    }
                    const response = await this.$http.post('/api/client/v1/product_order_options/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        additional_options: {},
                        product_ids: []
                    }
                    this.checkOptions = []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    this.addObject.additional_options = {}
                    if (this.checkOptions && this.checkOptions.length > 0) {
                        for (const option of this.options) {
                            const index = this.checkOptions.findIndex(x => x.value === option.value)
                            if (index > -1) this.addObject.additional_options[option.value] = 1
                        }
                    }

                    await this.$http.put(`/api/client/v1/product_order_options/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        additional_options: {},
                        product_ids: []
                    }
                    this.checkOptions = []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            selectionChanged(val) {
                this.addObject.product_ids = val
            },
            shouldDisableOption(option) {
                const hasFreeOrder = this.checkOptions.some(opt => opt.value === 'free_order_options')
                const hasSwapWithLower = this.checkOptions.some(opt => opt.value === 'swap_with_lower')

                if (hasFreeOrder) {
                    return option.value === 'swap_with_equal' || option.value === 'swap_with_lower'
                }

                if (hasSwapWithLower) {
                    return option.value === 'swap_with_equal'
                }

                return false
            },
            handleSelection() {
                const hasFreeOrder = this.checkOptions.some(opt => opt.value === 'free_order_options')
                const hasSwapWithLower = this.checkOptions.some(opt => opt.value === 'swap_with_lower')

                if (hasFreeOrder) {
                    this.checkOptions = this.checkOptions.filter(opt => opt.value !== 'swap_with_equal' && opt.value !== 'swap_with_lower')
                }

                if (hasSwapWithLower) {
                    this.checkOptions = this.checkOptions.filter(opt => opt.value !== 'swap_with_equal')
                }
            }
        },
        mounted() {
            this.loadData()
            this.products = this.productsRaw
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>